import createStyle from "@/utils/createStyle"

export const Container = createStyle(
  "div",
  "mx-auto w-full max-w-[90rem] px-4 md:px-6",
  {
    displayName: "container",
  }
)

export const KnowledgeTreeInnerContainer = createStyle(
  "div",
  "mx-auto max-w-7xl px-5",
  {
    displayName: "container",
  }
)

export const BookContentContainer = createStyle(
  "div",
  "relative mx-auto w-full max-w-[40rem] px-0 lg:px-6 xl:max-w-[45.5rem]",
  {
    displayName: "container",
  }
)

export const FullContainer = createStyle("div", "px-4 md:px-6")

export default Container
