import { FC } from "react"
import { Body2, Display5 } from "@/text"
import Image from "@/components/Image"
import Container from "@/components/Container"
import { PageMessageProps } from "./types"

export const PageMessage: FC<PageMessageProps> = ({
  title,
  description,
  imageSrc,
  buttonEl,
}) => {
  return (
    <div className="max-w-lg text-center">
      <Container>
        {imageSrc && (
          <Image
            src={imageSrc}
            alt="An icon image for display purposes"
            width="175"
            height="200"
            autoHeight
            className="mx-auto mb-16"
          />
        )}
        <Display5 className="mb-6">{title}</Display5>
        <Body2 className={buttonEl && "mb-6"}>{description}</Body2>
        {buttonEl && buttonEl}
      </Container>
    </div>
  )
}
export default PageMessage
