"use client"

import PageMessage from "@/components/PageMessage"
import Button from "@/components/Button"
import { useEffect } from "react"
import { captureException } from "@sentry/nextjs"

export default function ErrorPage({ error }: { error: any }) {
  useEffect(() => {
    console.error(error)
    captureException(error)
  }, [error])

  return (
    <main className="flex min-h-screen w-screen items-center justify-center bg-gradient-to-b from-red-100 to-blue-300 px-4 supports-oklab-grad:bg-gradient-oklab-to-b">
      <PageMessage
        imageSrc="/images/illustrations/oh-no.svg"
        title="Server Error"
        description="Oh no, something went wrong trying to access this page. Double check your URL or click the button below to go back."
        buttonEl={
          <Button href="/map" theme="light">
            Go Home
          </Button>
        }
      />
    </main>
  )
}
